import { onAbort } from '@farfetched/core'
import { createEffect } from 'effector'
import type { FetchOptions } from '~/api/client'
import { API } from '~/api/client'

export const deleteChannelParticipantRoleBaseFx = createEffect({
  handler: async (
    opts: FetchOptions<
      'delete',
      '/channels/{channel_id}/participants/{user_id}'
    >,
  ) => {
    const { data, error } = await API.manage.auth.DELETE(
      '/channels/{channel_id}/participants/{user_id}',
      opts,
    )
    if (error) throw error
    return data
  },
  name: 'deleteChannelParticipantRoleBaseFx',
})

export const changeChannelParticipantRoleBaseFx = createEffect(
  async (
    opts: FetchOptions<
      'patch',
      '/channels/{channel_id}/participants/{user_id}/role'
    >,
  ) => {
    const { data, error } = await API.manage.auth.PATCH(
      '/channels/{channel_id}/participants/{user_id}/role',
      opts,
    )
    if (error) throw error
    return data
  },
)

export const getChannelByIdBaseFx = createEffect({
  handler: async (opts: FetchOptions<'get', '/channels/{channel_id}'>) => {
    const abortController = new AbortController()

    onAbort(() => {
      abortController.abort()
    })

    opts.signal = abortController.signal

    const { data, error } = await API.manage.auth.GET(
      '/channels/{channel_id}',
      opts,
    )
    if (error) throw error
    return data
  },
})

export const toggleFavoriteChannelBaseFx = createEffect({
  handler: async (
    opts: FetchOptions<'patch', '/channels/{channel_id}/favorite'>,
  ) => {
    const { data, error } = await API.manage.auth.PATCH(
      '/channels/{channel_id}/favorite',
      opts,
    )
    if (error) throw error
    return data
  },
})

export const inviteParticipantBaseFx = createEffect({
  handler: async (opts: FetchOptions<'post', '/participant'>) => {
    const { data, error } = await API.manage.auth.POST('/participant', opts)
    if (error) throw error
    return data
  },
})

export const getChannelParticipantsBaseFx = createEffect({
  handler: async (
    opts: FetchOptions<'get', '/channels/{channel_id}/participants'>,
  ) => {
    const abortController = new AbortController()

    onAbort(() => {
      abortController.abort()
    })

    opts.signal = abortController.signal
    const { data, error } = await API.manage.auth.GET(
      '/channels/{channel_id}/participants',
      opts,
    )
    if (error) throw error
    return data
  },
})

export const getChannelsBaseFx = createEffect({
  handler: async (opts: FetchOptions<'get', '/channels'>) => {
    const abortController = new AbortController()

    onAbort(() => {
      abortController.abort()
    })

    opts.signal = abortController.signal
    const { data, error } = await API.manage.auth.GET('/channels', opts)
    if (error) throw error
    return data
  },
})
